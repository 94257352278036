:root {
  --bg-dark: #1b1b1b ;
  --bg-light: #CFDCEC;
  
  --text-dark: #1b1b1b;
  --text-light: #CFDCEC;

  --text-yellow: #FFB200;
  --text-yellow-light: #f5e9cc;

  --text-green: #1A9948;
  --text-green-light: #b3efc9;

  --text-orange: #F4511E;
  --text-orange-light: #f4d4ca;
  --text-orange-dark: #8d2508;

  --text-blue: #1875D0;
  --text-blue-light: #cbe0f5;

  --border-light: #CFDCEC;
  --border-dark: #010103;

  --button-light: var(--border-light);
  --button-dark: var(--border-dark);

} 

body{
  overflow-x: hidden !important;
  background-color: #1b1b1b !important;
}

.content {
  margin-left: 75px; 
  width: calc(100% - 75px);
}

.light-background{
  background-color: var(--bg-light);
}

.dark-background{
  background-color: #1b1b1b !important;
}

.grey-background{
  background-color: rgba(207, 220, 236, 0.08) !important;
}

.orange-light-background{
  background-color:var(--text-orange-light)!important;
}

.yellow-light-background{
  background-color: hsla(42, 100%, 50%, 0.2) !important;
  
}

.green-background{
  background-color: rgb(16, 92, 44) !important;
}

.gradient-green-background{
  background: linear-gradient(to right, var(--text-yellow),var(--text-orange-light) 50%, var(--text-green) 100%);
}

.light-green-bg{
  background-color: var(--text-light-green);
}

.h-100{
  min-height: 100vh;
}

.h-60{
  min-height: 60vh;
}

.h-50{
  min-height: 50vh;
}

.width-75px{
  width: 75px !important;
}


.width-80px{
  width: 80px !important;
}

.width-100px{
  width: 100px !important;
}

.width-250px{
  width: 250px !important;
}

.width-350px{
  width: 350px !important;
}

.width-50vw{
  width: 50vw;
}

.w-100vw{
  max-width: 100vw !important;
}

.w-20{
  max-width: 20% !important;
}

.w-50{
  max-width: 50% !important;
}

.w-70{
  max-width: 70% !important;
}


.header-height{
  height: 150px;
}

@media screen and (min-width: 768px) {
  .header-height{
    height: 25px;
  }
}


/* MENU */




.menu-item.active {
  background-color: var(--bg-light); 
  border-radius: 5px; 
  padding: 5px 5px;
}


a{
  text-decoration: none !important;
  cursor: pointer !important;
  color: var(--text-light) !important;
}

h1,h2,h3,h4,h5,h6{
  font-family: mundial, sans-serif;

}

p{
  margin: 0 !important;
  padding: 0 !important;
}


.capitalize{
  text-transform: capitalize;
}

.allCaps{
  text-transform: uppercase;
}


.fs-12{
  font-size: 12px;
}

.fs-14{
  font-size: 14px;
}

.fs-16{
  font-size: 16px;
}

.fs-18{
  font-size: 18px;
}

.fs-22{
  font-size: 22px;
}

.fs-24{
  font-size: 24px;
}

.fs-28{
  font-size: 28px;
}

.fs-32{
  font-size: 32px;
}

.fs-36{
  font-size: 36px;
}

.fs-42{
  font-size: 42px;
}

.fs-50{
  font-size: 50px !important;
}

.fs-2em{
  font-size: 2em !important;
  line-height: 1.1;
}

.fs-3em{
  font-size: 3em !important;
}

.fs-4em{
  font-size: 4em !important;
}

.fs-5em{
  font-size: 5em !important;
}


.fw-100{
  font-weight: 100;
}

.fw-200{
  font-weight: 200;
}

.fw-300{
  font-weight: 300;
}

.fw-400{
  font-weight: 400;
}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.text-dark{
  color: var(--text-dark) !important;
}

.text-light p{
  color: var(--text-light);
}

.text-green{
  color: var(--text-green) !important;
}

.text-green-light{
  color: var(--text-green-light) !important;
}

.text-yellow{
  color: var(--text-yellow) !important;
}

.text-yellow-light{
  color: var(--text-yellow-light) !important;
}

.text-orange{
  color: var(--text-orange) !important;
}

.text-orange-light{
  color: var(--text-orange-light) !important;
}

.text-blue{
  color: var(--text-blue) !important;
}

.text-blue-light{
  color: var(--text-blue-light) !important;
}


.primary-btn {
  background-color: var(--bg-dark) !important;
  box-shadow: none;
  border: 1px solid var(--text-yellow);
  color: var(--text-yellow);
  
}


.pointer{
  cursor: pointer !important;
}

.cursor-none{
  cursor: none !important;

}

.border-6{
  border-radius: 6px;
}

.border-light-1px{
  border: 1px solid #CFDCEC
}


.border-light-1px{
  border: 1px solid hsla(213, 43%, 87%, 0.20);
}


.border-bottom-dark{
  border-bottom: 3px solid var(--border-dark)
}

.border-bottom-light-1px{
  /* border-bottom: 1px solid var(--bg-light); */
  border-bottom: 1px solid hsla(213, 43%, 87%, 0.20);
}

.border-top-light-1px{
  /* border-bottom: 1px solid var(--bg-light); */
  border-top: 1px solid hsla(213, 43%, 87%, 0.20);
}

.border-bottom-light-1px-content{
  border-bottom: 1px solid hsla(214, 42%, 87%, 0.5)

}

.border-bottom-dark-1px{
  border-bottom: 1px solid var(--border-dark)
}

.border-right-yellow-1px{
  border-right: 1px solid hsla(42, 67%, 88%, 0.25)
}

.border-right-1px{
  border-right: 1px solid  hsla(213, 43%, 87%, 0.25)
}

.border-bottom-yellow-light-1px{
  border-bottom: 1px solid hsla(42, 67%, 88%, 0.25)
}


.border-top-yellow-light-1px{
  border-top: 1px solid hsla(42, 67%, 88%, 0.25)
}

.border-bottom-yellow-light-1px-content{
  border-bottom: 1px solid hsla(42, 67%, 88%, 0.9)
}


.border-bottom-green-light-1px{
  border-bottom: 1px solid var(--text-green-light)

}

.border-bottom-orange-1px{
  border-bottom: 1px solid var(--text-orange)
}

.border-top-orange-1px{
  border-top: 1px solid var(--text-orange)
}


.border-bottom-orange-light-1px{
  border-bottom: 1px solid var(--text-orange-light)
}

.border-bottom-blue-1px{
  border-bottom: 1px solid var(--text-blue)
}

.border-bottom-blue-light-1px{
  border-bottom: 1px solid var(--text-blue-light)
}


.border-bottom-orange-dark-1px{
  border-bottom: 1px solid var(--text-orange-dark)
}

.border-yellow-light{
  border: 1px solid var(--text-yellow-light)

}

.border-grey{
  border: 1px solid var(--text-grey)
}

.border-yellow{
  border: 1px solid var(--text-yellow)
}

.border-orange{
  border: 1px solid var(--text-orange)
}

.border-blue{
  border: 1px solid var(--text-blue)
}

.border-green{
  border: 1px solid var(--text-green)
}


/* table and checkbox */

.form-check-input{
  background-color: #010103 !important;
}

.form-check-input:checked{
  background-color: var(--text-orange) !important;
  border: none;
}








/* inputs */


.editable-form {
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  padding: 5px;
  border-radius: 4px;
}


.form-control{
  border: none !important;
  border-radius: 0 !important;
  color: var(--bg-light) !important;
  background-color: rgba(207, 220, 236, 0) !important;

}


.form-control::placeholder{
  font-weight: 500;
  color: var(--bg-light) !important;

}


.form-control-details{
  border-radius: 0 !important;
  background-color: rgba(207, 220, 236, 0) !important;
  width: 100%;

}

.form-control-details-clean{
  border: none !important;
  border-radius: 0 !important;
  background-color: rgba(207, 220, 236, 0.1) ;
  width: 100%;
  font-size: 16px;
}

.form-input-sketch{
  border-bottom: 1px solid var(--text-blue-light);
  border-radius: 0 !important;
  background-color: hsla(14, 66%, 87%, 0.185) !important;
  width: 100%;
  font-size: 14px;
}

.form-input-sketch::placeholder{
  color: var(--text-orange-light) !important;
}




input{
  background-color: rgba(207, 220, 236, 0.2);
  color: var(--text-light) !important;
}

input::placeholder{
  color: var(--text-yellow-light) !important;
  opacity: 0.5 !important;
}


textarea{
  border: none !important;
  border-radius: 0 !important;
  background-color: rgba(207, 220, 236, 0.10) !important;
  color: var(--text-light) !important;
}



/* Drop Down */


.pipeline-menu{
  position: absolute;
  max-width: 250px;
  height: auto;
  right: 0;
  text-align: right;
}

.pipeline-menu-content {
  background-color: hsla(213, 43%, 87%, 0.05);
  backdrop-filter: blur(20px); 
  -webkit-backdrop-filter: blur(10px); 
  border-radius: 4px;
  border: 1px solid hsla(46, 100%, 95%, 0.2);
  margin: 0;
  padding: 0;
}

.pipeline-menu-content li {
  list-style: none !important;
  cursor: pointer;
  padding: 0px 10px;
}



/* POP UP */

.popup_container {
  position: absolute;
  width: 100%;
  height: auto;
  /* justify-content:center;
  align-items:center; */
  z-index: 999;
  top: 2%;
  left: 50%;
  transform: translate(-50%, 0%);

}

.popup_content {
  background-color: #cfdcec08;
  backdrop-filter: blur(20px); 
  -webkit-backdrop-filter: blur(20px); 
  text-align: center;
  border-radius: 4px;
  border: 1px solid hsla(46, 100%, 95%, 0.2);

}

input::placeholder{
  color: var(--text-light) !important;
  opacity: 0.5 !important;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 12, 0.2);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: absolute;
  background-color: rgba(12, 12, 12, 0.1);
  backdrop-filter: blur(8px);
  padding: 20px;
  border-radius: 5px;
  border: 1px solid hsla(46, 100%, 95%, 1);

}



.box-container{
  height: 100vh;
  overflow-y: scroll;
}

.box-container::-webkit-scrollbar {
  width: 6px; 
}

.box-container::-webkit-scrollbar-track {
  background-color: #0c0c0c;
}

.box-container::-webkit-scrollbar-thumb {
  background-color: #CFDCEC;
  border-radius: 3px; 
}

.box-container::-webkit-scrollbar-thumb:hover {
  background-color: #CFDCEC;
}

.company_logo{
  max-width: 125px;
}
.custom-table-dark {
  background-color: #343a40 !important; /* Your desired background color */
}

.input-sum[type="number"]::-webkit-inner-spin-button,
.input-sum[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-sum[type="number"] {
  border: none !important;
}

.custom-table{
  border-collapse: collapse;
  box-sizing: border-box;
  caption-side: bottom;
  display: table;
  line-height: 24px;
  text-align: start;
  text-indent: 0px;
  vertical-align: top;
  width: 100%;
  -webkit-border-horizontal-spacing: 2px;
  -webkit-border-vertical-spacing: 2px;
}

.lottie{
filter: grayscale();
filter: brightness(200);
}



